
import themeMixin from '~/mixins/theme'

export default {
  name: 'SliceWithVideo',
  mixins: [themeMixin],
  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  computed: {
    posterImageURL() {
      return this.slice.primary.webp?.url
    },
    desktopVideoUrl() {
      return this.slice.primary.mp4?.url
    },
    tabletVideoUrl() {
      return this.slice.primary.mp4_tablet?.url
    },
    mobileVideoUrl() {
      return this.slice.primary.mp4_mobile?.url
    },
    videoSrc() {
      if (this.$breakpoints.sSm) {
        // mobile
        return (
          this.mobileVideoUrl || this.tabletVideoUrl || this.desktopVideoUrl
        )
      }

      if (this.$breakpoints.sMd) {
        // tablet
        return this.tabletVideoUrl || this.desktopVideoUrl
      }

      // desktop
      return this.desktopVideoUrl
    },
  },
  methods: {
    clickedScrollIndicator() {
      this.$emit('onClickScrollIndicator')
    },
  },
}
