
import { getSliceComponentProps } from '@prismicio/vue/components'
import TocItems from './tocItems'
import { formatDate } from '@/utils/formatters'
import defaultHTMLSerializer from '~/app/prismic/html-serializer'

export default {
  name: 'TitleAndRightDescription',
  components: {
    TocItems,
  },
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  computed: {
    formattedDate() {
      return formatDate(
        this.context?.document?.data?.published_date ||
          this.context?.document?.first_publication_date
      )
    },
    postType() {
      return this.context?.document?.data?.post_type?.data?.name
    },
    readingTime() {
      return this.context?.document?.data?.reading_time
    },
    tableOfContents() {
      const slices = []

      const insertOnLevel = (item) => {
        const text = item?.text || item?.primary?.title[0]?.text
        const type = item?.type || item?.primary?.title[0]?.type

        const content = {
          text,
          children: [],
        }

        if (type === 'heading2') {
          slices.push(content)
        } else if (type === 'heading3' && slices.length) {
          slices[slices.length - 1].children.push(content)
        } else if (
          type === 'heading4' &&
          slices[slices.length - 1]?.children?.length
        ) {
          const firstLevelLast = slices.length - 1
          const secondLevelLast = slices[firstLevelLast].children.length - 1
          slices[firstLevelLast].children[secondLevelLast].children.push(
            content
          )
        }
      }

      this.context?.document?.data?.slices.forEach((slice) => {
        if (
          slice.slice_type === 'title_and_text_section' ||
          slice.slice_type === 'title_and_numbered_text'
        ) {
          const descriptions = slice.primary.description

          insertOnLevel(slice)

          descriptions?.forEach((item) => {
            insertOnLevel(item)
          })
        }
      })

      return slices
    },
  },
  methods: {
    contentBody(type, element, content, children) {
      const customHTMLClassConfig = {
        paragraph: 'text-size-h6-mobile lg:text-size-h5-tablet',
        listItem: 'text-size-h6-mobile lg:text-size-h5-tablet',
      }

      return defaultHTMLSerializer(
        type,
        element,
        content,
        children,
        customHTMLClassConfig
      )
    },
  },
}
