
import Engine from './animations/engine'
export default {
  name: 'SliceWithImage',
  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  async mounted() {
    const slice = this.slice.primary
    const animationName = slice.animation.replace(/\s+/g, '').toLowerCase()
    const speed = slice.speed

    const { assets, animations, settings } = await import(
      `./animations/${animationName}`
    )
    const wrapper = this.$refs.animationWrapper
    Object.assign(settings, { speed })

    const engine = new Engine({ wrapper, settings })
    engine.preloadAssets(assets)
    engine.addAnimations(animations)
    engine.on('assetsLoaded', engine.play)
  },
  methods: {
    clickedScrollIndicator() {
      this.$emit('onClickScrollIndicator')
    },
  },
}
