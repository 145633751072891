export default class Engine {
  constructor(options = {}) {
    Object.assign(this, options)
    this.canvas = document.createElement('canvas')
    this.context = this.canvas.getContext('2d')
    this.wrapper.appendChild(this.canvas)

    this.canvas.width = this.wrapper.offsetWidth
    this.canvas.height = this.wrapper.offsetHeight
    this.canvas.style.width = '100%'
    this.canvas.style.height = '100%'

    this.events = { assetsLoaded: [] }
    this.timeline = []
    this.assets = []
    new ResizeObserver(this.onResize.bind(this)).observe(this.wrapper)
  }

  preloadAssets(assets = []) {
    Promise.all(
      assets.map((asset) => {
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.onload = () => resolve(img)
          img.onerror = reject
          img.src = asset
        })
      })
    )
      .then((resources) => {
        this.assets = [...this.assets, ...resources]
        this.emit('assetsLoaded', resources)
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  onResize(ev) {
    const [data] = ev
    this.canvas.width = data.contentRect.width
    this.canvas.height = data.contentRect.height
  }

  on(event, cb) {
    this.events[event].push(cb)
  }

  emit(eventName, payload) {
    this.events[eventName].forEach((cb) => cb.call(this, payload))
  }

  addAnimations(animations = []) {
    if (Array.isArray(animations)) {
      this.timeline = [...this.timeline, ...animations]
    } else {
      this.timeline.push(animations)
    }
  }

  play(t) {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)

    this.timeline.forEach((animation) => animation.call(this, t))
    window.requestAnimationFrame(this.play.bind(this))
  }
}
