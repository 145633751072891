
import { getSliceComponentProps } from '@prismicio/vue/components'
import SliceWithCards from './default-slice'
import SliceWithImage from './withImage'
import SliceWithAnimation from './withAnimation'
import TitleAndRightDescription from './titleAndRightDescription'
import BlogPostHeader from './blogPostHeader'
import SliceWithVideo from './pageHeaderWithVideo'

export default {
  name: 'PageHeader',
  components: {
    SliceWithCards,
    SliceWithImage,
    SliceWithAnimation,
    TitleAndRightDescription,
    BlogPostHeader,
    SliceWithVideo,
  },
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),

  computed: {
    variation() {
      return this.slice.variation
    },
    contextDocument() {
      return this.context?.document || {}
    },
    component() {
      const mappings = {
        'default-slice': 'SliceWithCards',
        withImage: 'SliceWithImage',
        withAnimation: 'SliceWithAnimation',
        titleAndRightDescription: 'TitleAndRightDescription',
        blogPostHeader: 'BlogPostHeader',
        pageHeaderWithVideo: 'SliceWithVideo',
      }
      return mappings[this.variation]
    },
  },

  methods: {
    initiatePageScroll() {
      this.$refs.scrollToRef.scrollIntoView({ behavior: 'smooth' })
    },
  },
}
