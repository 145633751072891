
import TocItems from './tocItems'
import { djb2 } from '~/utils/hash'

export default {
  name: 'TocItems',
  components: {
    TocItems,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    level: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  methods: {
    getId(content) {
      return djb2(content)
    },
  },
}
