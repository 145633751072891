import { render, staticRenderFns } from "./index.vue?vue&type=template&id=756e6a5f&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutSpacingHorizontal: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Layout/Spacing/Horizontal.vue').default,LayoutSpacingVerticalMediumBlogHeader: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Layout/Spacing/VerticalMediumBlogHeader.vue').default})
