
export default {
  name: 'SliceWithCards',
  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  methods: {
    clickedScrollIndicator() {
      this.$emit('onClickScrollIndicator')
    },
  },
}
